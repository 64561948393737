/** @format */

body {
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

a {
  color: #d32f2f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.MuiButton-containedPrimary {
  background-color: #d32f2f;
}

.MuiButton-containedPrimary:hover {
  background-color: #b71c1c;
}

/* styles.css */

.hero-section {
  height: 60vh;
  background-image: url("https://red-parts.react.themeforest.scompiler.ru/themes/red/images/finder.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 8px;
}

.hero-heading {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.hero-button {
  background-color: #f50057;
  color: white;
  &:hover {
    background-color: #c51162;
  }
}

@media (max-width: 600px) {
  .hero-heading {
    font-size: 2rem;
  }
  .hero-subheading {
    font-size: 1rem;
  }
}
